import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

import { VerificationStep } from "../../types";
import OtpInput from "react-otp-input";
import { useAppDispatch } from "../../redux/hooks";
import { setHasUserVerified } from "../../redux/api/userSlice";

import { verifyCode, sendVerificationCode } from "../../firebase/verification";

import { useGetCurrentUser } from "../../hooks/useGetCurrentUser";
interface IConfirmSms {
	handleGoToStep: (arg: VerificationStep) => void;
	confirmationResult: any;
	verifiedPhoneNumber: string;
	setConfirmationResult: (arg: any) => void;
	closeVerifyWindow: () => void;
}

const ConfirmSms = ({
	handleGoToStep,
	confirmationResult,
	verifiedPhoneNumber,
	setConfirmationResult,
	closeVerifyWindow,
}: IConfirmSms) => {
	const dispatch = useAppDispatch();
	const location = useLocation();
	const { t } = useTranslation();
	const [otp, setOtp] = useState("");
	const [error, setError] = useState({ state: false, message: "" });
	const [isLoading, setIsLoading] = useState(false);

	const { user } = useGetCurrentUser();
	const userId = user?.uid;

	const verifyOtp = async () => {
		setIsLoading(true);
		setError({ state: false, message: "" });

		try {
			if (userId) {
				const data = await verifyCode(confirmationResult, otp, userId);
				dispatch(setHasUserVerified(true));

				if (data && data.error) {
					setError({ state: true, message: data.error });
					console.log(data.error);
					return;
				}
			}

			if (location.pathname.includes("project")) {
				closeVerifyWindow();
			} else {
				handleGoToStep("claimVerificationTokens");
			}
		} catch (err) {
			console.error(err);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className="max-w-[320px] w-[100%] my-0 mx-auto flex flex-col gap-[32px]">
			<div className="">
				<span className="uppercase text-base font-normal mb-[4px]">
					{t("profileModal.step", { step: "3" })}
				</span>
				<h3 className="font-poppinsBold text-[22px]">
					{t("verification.chooseOption.chooseTitle")}
				</h3>
			</div>
			<div className="mb-[120px]">
				<p className="mb-[16px]">{t("verification.otpTitle")}</p>
				<OtpInput
					value={otp}
					onChange={setOtp}
					numInputs={6}
					inputStyle={{
						width: "45px",
						height: "45px",
						borderRadius: "50%",
						border: "2px solid #D9D9D9",
					}}
					containerStyle={{ display: "flex", gap: "8px" }}
					renderInput={(props) => <input {...props} />}
				/>
				{error.state && <p className="text-error">{error.message}</p>}
			</div>
			<div className="">
				<button
					disabled={isLoading}
					onClick={verifyOtp}
					className="flex items-center gap-[8px] bg-black py-[16px] w-full justify-center font-poppinsBold px-[40px] rounded-[50px] text-white mb-[16px] disabled:bg-midGray"
				>
					{t("verification.otpConfirm")}
				</button>
				<div className="flex justify-center gap-[5px]">
					<p>{t("verification.otpNoSms")}</p>
					<button
						onClick={async () => {
							try {
								const data = await sendVerificationCode(
									verifiedPhoneNumber,
									setConfirmationResult
								);

								if (data && data.error) {
									setError({ state: true, message: data.error });
									return;
								}
							} catch (err) {
								console.error(err);
							}
						}}
					>
						{t("verification.otpSendAgain")}
					</button>
				</div>
			</div>
			<div className="text-center font-Poppins max-w-[340px]">
				<p>{t("verification.otpNotWorking")}</p>
				<p>
					{t("verification.otpContact")}{" "}
					<a href="mailto: team@parry.vote">team@parry.vote</a>
				</p>
			</div>
		</div>
	);
};

export default ConfirmSms;
