import PagesContainer from "../startPage/PagesContainer";

const Contact = () => {
  return (
    <>
      <PagesContainer title="Impressum">
        <div className="flex leading-[22px] flex-col gap-y-4 font-poppinsBold mb-[250px]">
          <div>
            <p>CitizenTech UG (haftungsbeschränkt)</p>
            <p>Mühlenstr. 8a</p>
            <p>14167 Berlin</p>
          </div>
          <div>
            <p>Amtsgericht Berlin Charlottenburg — HRB 244285 B</p>
          </div>
          <div>
            <p>Managing Director: Maximilian Kuck</p>
            <p>team(at)parry.vote</p>
          </div>
        </div>
      </PagesContainer>
    </>
  );
};

export default Contact;
