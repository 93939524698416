import { useTranslation } from "react-i18next";
import { getAuth, sendSignInLinkToEmail } from "firebase/auth";
import { useState } from "react";

export const ConfirmLoginEmail = () => {
  const { t } = useTranslation();
  const [info, setInfo] = useState({ success: true, message: " " });

  const resendLink = async () => {
    try {
      const email = localStorage.getItem("emailForSignIn") || "";

      const REDIRECT_DOMAIN = process.env.REACT_APP_WEBSITE_URL!;

      const actionCodeSettings = {
        url: REDIRECT_DOMAIN,
        handleCodeInApp: true,
      };

      const auth = getAuth();

      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      setInfo({ success: true, message: t("auth.login.resend") });

      setTimeout(() => {
        setInfo({ success: true, message: " " });
      }, 3000);
    } catch {
      setInfo({ success: true, message: t("auth.login.failedSendLink") });
    }
  };

  return (
    <div>
      <h3 className="uppercase text-base font-normal">
        {t("auth.login.step", { step: "2" })}
      </h3>
      <h1 className="font-semibold text-2xl mt-2">{t("header.login")}</h1>
      <p className="mt-10">{t("auth.login.confirmDescription")}</p>
      <p className="mt-8">
        {t("auth.login.contactUs")}{" "}
        <a className="text-outlineBlue underline" href="mailto:team@parry.vote">
          team@parry.vote
        </a>
      </p>
      <div className="flex flex-col grow items-center gap-2">
        <button
          className="underline mx-auto mt-6 text-center"
          onClick={resendLink}
        >
          {t("auth.login.resendLink")}
        </button>
        <p className={info.success ? "text-midGreen" : "text-error"}>
          {info.message}
        </p>
      </div>
    </div>
  );
};
