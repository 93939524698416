import TextSection from "../../shared/TextSection";
import Wrapper from "../../shared/Wrapper";
import ContactForm from "./ContactForm";
import Header from "../header/Header";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../redux/hooks";
import MobileNoAccess from "../mobileNoAccess/MobileNoAccess";
const ContactSection = () => {
  const {t} = useTranslation();
  const isMobile = useAppSelector((state) => state.elementsView.isMobile);

  return (
    <>
      <Header disableHide>
        <div className=" flex items-center justify-center text-2xl">
          <p>{t("contact.header-link")}</p>
        </div>
      </Header>
      {isMobile ? (
        <MobileNoAccess />
      ) : (
        <Wrapper>
          <TextSection title={t("contact.column.title")}>
            <div className="flex flex-col gap-y-3">
              <p className="w-full">{t("contact.column.text_1")}</p>
              <p className="w-full">{t("contact.column.text_2")}</p>
              <p className="w-full">{t("contact.column.text_3")}</p>
              <div>
                <p>Email: team(at)parry.vote</p>
                <p>Post:</p>
                <p>CitizenTech UG</p>
                <p>Mühlenstr. 8a, 14167 Berlin</p>
              </div>
              <p className="w-full">{t("contact.column.text_4")}</p>
            </div>
          </TextSection>
          <ContactForm />
        </Wrapper>
      )}
    </>
  );
};

export default ContactSection;
